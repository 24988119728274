<template>
<div class="Logout">
    <p>Logging out</p>
</div>
</template>

<script>
import API from "./../API.js";
import { UserStore } from "../UserStore";
export default {
    data(){
        return {
            UserStore
        }
    },
    mounted(){
        API.logout().then(()=>{
            this.$router.push('/');
            this.UserStore.getMe();
        });
    }
}
</script>

<style>

</style>